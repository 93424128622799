export const environment = {
  production: true,
  resourceServerURL: 'http://localhost:8081',
  apiServer: 'https://launcher.fullswingapps.com/api/fs',
  awsAPIURL: 'https://l9otz28laj.execute-api.us-west-2.amazonaws.com/prod',
  hostName: 'baymanageradmin.fullswingapps.com',
  appConfigPath: './assets/config/app-config.json',
  defaultPage: 'dashboard',
  //Local strorage values
  navName: '_fs_nav_name',
};
