const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_KWjsmZvMg",
    "aws_user_pools_web_client_id": "3t3v8hl8c68gisuualujvduppn",
    "oauth": {
        "domain": "baymanageridp.fullswingapps.com",
        "scope": [
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
      "redirectSignIn": "https://baymanageradmin.fullswingapps.com",
      "redirectSignOut": "https://baymanageradmin.fullswingapps.com/login",
        "responseType": "code"
    }
};

export default awsmobile;
